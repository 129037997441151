var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"exportfailed",attrs:{"fluid":""}},[_c('closeheader',{attrs:{"to":"./../../"}}),_vm._v(" "),_c('b-row',[_c('b-col',{attrs:{"cols":"2"}}),_vm._v(" "),_c('b-col',{staticClass:"text-center",attrs:{"cols":"8"}},[_c('font-awesome-icon',{staticClass:"mt-4 mb-4",attrs:{"icon":"exclamation-circle","size":"3x","fixed-width":""}}),_vm._v(" "),(_vm.exportResult.errorTypeString === 'UnexpectedError')?_c('div',[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('TL_SHARED_UNEXPECTED_ERROR')))]),_vm._v("\n           "+_vm._s(_vm.$t('TL_USER_EXPORT_SOMETHING_BLEW_UP'))+"\n        ")])]):(
          _vm.exportResult.errorTypeString === 'ExportCancelled'
        )?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('TL_SHARED_EXPORT_CANCELLED')))]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_YOUR EXPORT WAS CANCELLED'))+"\n        ")])]):(_vm.exportResult.errorTypeString === 'PrecheckError')?_c('div',[(
            _vm.exportResult.publishResult.errorTypeString ===
            'UnexpectedError'
          )?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('TL_SHARED_UNEXPECTED_ERROR')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('TL_USER_EXPORT_SOMETHING_BLEW_UP')))])]):_vm._e(),_vm._v(" "),(
            _vm.exportResult.precheckResult.errorTypeString ===
            'CouldNotConnectToIntegration'
          )?_c('div',[_c('h3',[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_COULD_NOT_CONNECT'))+"\n          ")]),_vm._v(" "),_c('p',[_c('i18n',{attrs:{"path":"TL_SHARED_EXPORT_FAILED_PLEASE RECONNECT"},scopedSlots:_vm._u([{key:"integrationname",fn:function(){return [_vm._v("\n                "+_vm._s(_vm.exportResult.integration.integrationFriendlyName)+"\n              ")]},proxy:true},{key:"link",fn:function(){return [_c('router-link',{attrs:{"to":"/settings/integrations/"}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_INTEGRATIONS_PAGE')))])]},proxy:true}],null,false,40984698)})],1)]):_vm._e(),_vm._v(" "),(
            _vm.exportResult.precheckResult.errorTypeString ===
            'RequeuedExportToIntegrationStillRunning'
          )?_c('div',[_c('h3',[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_ALREADY RUNNING'))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_A_PREVIOUS_EXPORT_TO', {
                integrationname:
                  _vm.exportResult.integration.integrationFriendlyName,
              }))+"\n          ")])]):_vm._e(),_vm._v(" "),(
            _vm.exportResult.precheckResult.errorTypeString ===
            'RequeuedSystemMaintenance'
          )?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('TL_EXPORT_PAUSED')))]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_THIS'))+"\n          ")])]):_vm._e(),_vm._v(" "),(
            _vm.exportResult.precheckResult.errorTypeString ===
            'KindleExportWithNoKindleEmailAddress'
          )?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_KINDLE')))]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_PLEASE_CONFIGURE_KINDLE'))+"\n          ")])]):_vm._e()]):(
          _vm.exportResult.errorTypeString === 'GenerationError'
        )?_c('div',[(
            _vm.exportResult.generationResult.errorTypeString ===
            'UnexpectedError'
          )?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('TL_SHARED_UNEXPECTED_ERROR')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('TL_USER_EXPORT_SOMETHING_BLEW_UP')))])]):_vm._e(),_vm._v(" "),(
            _vm.exportResult.generationResult.errorTypeString ===
            'NoRecordsToExport'
          )?_c('div',[_c('h3',{staticClass:"title"},[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_NO_RECORDS'))+"\n          ")]),_vm._v(" "),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_NO_RECORDS_WERE_EXPORTED')))]),_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_PLEASE_SELECT_DIFFERENT'))+"\n          ")])]):_vm._e(),_vm._v(" "),(
            _vm.exportResult.generationResult.errorTypeString ===
            'AllRecordsAlreadyExported'
          )?_c('div',[_c('h3',{staticClass:"title"},[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_NO_NEW_RECORDS'))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_YOU_HAVE_ALREADY', {
                integrationname:
                  _vm.exportResult.integration.integrationFriendlyName,
              }))+"\n          ")]),_vm._v(" "),_c('p',[_c('i18n',{attrs:{"path":"TL_SHARED_EXPORT_FAILED_IF_YOU_WOULD_LIKE"},scopedSlots:_vm._u([{key:"integrationname",fn:function(){return [_vm._v("\n                "+_vm._s(_vm.exportResult.integration.integrationFriendlyName)+"\n              ")]},proxy:true},{key:"link",fn:function(){return [_c('a',{attrs:{"to":"'/settings/integrations/'"}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_INTEGRATIONS_PAGE')))])]},proxy:true}],null,false,4130353597)})],1)]):_vm._e()]):(_vm.exportResult.errorTypeString === 'PublishError')?_c('div',[(
            _vm.exportResult.publishResult.errorTypeString ===
            'NotAuthorised'
          )?_c('div',[_c('h3',[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_COULD_NOT_CONNECT'))+"\n          ")]),_vm._v(" "),_c('i18n',{attrs:{"path":"TL_SHARED_EXPORT_FAILED_PLEASE RECONNECT"},scopedSlots:_vm._u([{key:"integrationname",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.exportResult.integration.integrationFriendlyName)+"\n            ")]},proxy:true},{key:"link",fn:function(){return [_c('a',{attrs:{"to":"'/settings/integrations/'"}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_INTEGRATIONS_PAGE')))])]},proxy:true}],null,false,4099283773)})],1):_vm._e(),_vm._v(" "),(
            _vm.exportResult.publishResult.errorTypeString ===
            'ApiLimitReached'
          )?_c('div',[_c('h3',[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_API LIMIT_REACHED'))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_API LIMIT_REACHED_TEXT'))+"\n          ")])]):_vm._e(),_vm._v(" "),(
            _vm.exportResult.publishResult.errorTypeString ===
            'UploadLimitReached'
          )?_c('div',[_c('h3',[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_UPLOAD LIMIT_REACHED'))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'TL_SHARED_EXPORT_FAILED_UPLOAD LIMIT_REACHED_TEXT'
              ))+"\n          ")])]):_vm._e(),_vm._v(" "),(
            _vm.exportResult.publishResult.errorTypeString ===
            'UnexpectedError'
          )?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('TL_SHARED_UNEXPECTED_ERROR')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('TL_USER_EXPORT_SOMETHING_BLEW_UP')))])]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.showNewExport)?_c('b-button',{attrs:{"to":"./../wizard","szie":"lg","variant":"primary","target":"_self"}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_NEW_EXPORT').toUpperCase()))]):_vm._e(),_vm._v(" "),(_vm.showCancelPreviousExports)?_c('b-button',{attrs:{"to":".","variant":"outline-success","target":"_self"}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_EXPORT_FAILED_WAIT FOR EXPORTS TO COMPLETE')))]):_vm._e(),_vm._v(" "),_c('div',[(_vm.showCancelPreviousExports)?_c('b-button',{staticClass:"mt-4",attrs:{"variant":"outline-danger","target":"_self"},on:{"click":function($event){return _vm.cancelPreviousExports()}}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_CANCEL_RUNNING_EXPORTS').toUpperCase()))]):_vm._e()],1)],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"2"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }