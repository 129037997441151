












































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import closeheader from '@/components/closeheader.vue';

@Component({
  components: {
    closeheader,
  },
})
export default class ExportFailedPage extends Vue {
  get showCancelPreviousExports() {
    if (this.exportResult!.precheckResult) {
      return (
        this.exportResult!.precheckResult.errorTypeString ===
        'ExportToIntegrationStillRunning'
      );
    } else {
      return false;
    }
  }

  get systemMaintenance() {
    if (this.exportResult!.precheckResult) {
      return (
        this.exportResult!.precheckResult.errorTypeString ===
        'RequeuedSystemMaintenance'
      );
    } else {
      return false;
    }
  }

  get showNewExport() {
    return !this.showCancelPreviousExports && !this.systemMaintenance;
  }

  get exportResult() {
    return ExportUiStateModule.completeExportById(
      Number(this.$route.params.exporthistoryid),
    );
  }
  async cancelPreviousExports() {
    ExportUiStateModule.cancelPreviousExportJobs();
    Vue.toasted.success(
      this.$t('TL_EXPORT_FAILED_PREVIOUS_EXPORTS_FAILED').toString(),
      {
        duration: 10000,
        position: 'top-center',
      },
    );
    this.$router.push('.');
  }
}
